import type Glide from "@glidejs/glide";

export function disableGlidePerSlideAmount(glide: Glide, glideElement: HTMLElement): void {
	const slideCount = glideElement.querySelectorAll(".glide__slide").length;
	if (slideCount < glide.settings.perView) {
		glide.update({ startAt: 0 }).disable();
	} else {
		glide.enable();
	}
}
